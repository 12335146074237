import { EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA } from "domain/entity/GeneralProposal/GeneralProposalSearchCriteria";
import moment from "moment";
import { useGeneralProposalVM } from "presentation/hook/GeneralProposal/useGeneralProposalVM";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import GeneralProposalRejectReasonModal from "./GeneralProposalRejectReasonModal";
import GeneralProposalTitleBar from "./GeneralProposalTitleBar";
import GeneralProposalSearchPanel from "./Left/GeneralProposalSearchPanel";
import GeneralProposalRightPanel from "./Right/GeneralProposalRightPanel";

const GeneralProposalMaintenance:React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [generalProposalState] = useGeneralProposalTracked();
    const generalProposalVM = useGeneralProposalVM();
    const {searchStatus,searchCriteria,isShowCriteriaPanel,isAllowAutoSearch,isBackFromDetail} = generalProposalState;
    

    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    generalProposalVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        console.log(`Table init successful!`);
                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        isAllowAutoSearch && initialScreen().then(async () => {  
            await generalProposalVM.searchAllGeneralProposal();
            if(searchStatus === ""){
                let newSearchCriteria = null;
                if(isBackFromDetail){
                    newSearchCriteria = {...searchCriteria};
                }else{
                    console.log("GeneralProposalMaintenance initialScreen effectiveDateFrom:"+moment().subtract(2, "years").startOf("year").format('YYYY-MM-DD HH:mm:ss'));
                    console.log("GeneralProposalMaintenance initialScreen effectiveDateTo:"+moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss'));
    
                    newSearchCriteria = {...EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA} 
                    newSearchCriteria = { ...newSearchCriteria, 
                        effectiveDateFrom: moment().subtract(2, "years").startOf("year").toDate(),
                        effectiveDateTo: moment().endOf('year').endOf('day').toDate(), 
                    };
                }
                generalProposalVM.searchGeneralProposal(newSearchCriteria).then((data) => {            
                    setIsLoading(false)
                }).catch(error => {            
                    setIsLoading(false)
                });
            }else{
                generalProposalVM.onGroupButtonClick(searchStatus).then((data) => {
                    setIsLoading(false);
                }).catch(error => {            
                    setIsLoading(false);
                }).finally(() => {
                    setIsLoading(false);
                })
            }
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [isAllowAutoSearch, isBackFromDetail, searchCriteria, searchStatus, generalProposalVM])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
           <GeneralProposalTitleBar/>
           {generalProposalState.isRejecting && <Loader Indicator="Spinner" size="Medium" /> }
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                leftChildren={<GeneralProposalSearchPanel/>}
                rightChildren={<GeneralProposalRightPanel/>} 
                />
            <div className='im-charge-data-search-confirm-modal-container'>
            { <GeneralProposalRejectReasonModal/> }

        </div>
        </div>
    </>
}

export default memo(GeneralProposalMaintenance);