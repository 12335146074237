import { SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF, transferRowData } from "presentation/constant/GeneralProposal/GeneralProposalTariffItemTierColumnDefinition";
import { useGeneralProposalVM } from "presentation/hook/GeneralProposal/useGeneralProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";
import GeneralProposalHeaderBar from "./GeneralProposalHeaderBar";
import { TariffItemIsEditPanel } from "./TariffItemIsEditPanel";
import TariffItemIsTablePanel from "./TariffItemIsTablePanel";


const TariffItemTierTablePanel: React.FC = () => {
    const [generalProposalState] = useGeneralProposalTracked();
    const generalProposalVM = useGeneralProposalVM();
    const { searchStatus, searchCriteria, generalProposalItemTierList,generalProposalItemIsList,tariffItemTierSelectedRows,currentTariffItemTier,currentSelectedRow,isEditingProposal, isAddIs,isAddTier,isEditIs,isEditTier } = generalProposalState;
    let gridRef: any = useRef();
    const messageBarVM = useMessageBarVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        if("NEW" === currentSelectedRow.status || "REJECTED" === currentSelectedRow.status){
            const selectedRows = e.api.getSelectedRows();
            generalProposalVM.updateSelectedTariffItemTierRows(selectedRows);
        }
        
    }, [currentSelectedRow.status, generalProposalVM])


    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF.slice());
        
        // if (!generalProposalState.tariffItemTierSelectedRows ||
        //     generalProposalState.tariffItemTierSelectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })

    const handleAdd = useCallback(() => {
        if("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status){
            messageBarVM.showWarining("General Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot Add.");
            return;
        }
        if(!_.isEmpty(generalProposalItemTierList) && generalProposalItemTierList.length === 1 && !_.isEmpty(generalProposalItemIsList)){
            messageBarVM.showWarining("Intensive surcharge does not support multiple charge tier.");
            return;
        }

        generalProposalVM.onAddTier();
    }, [messageBarVM, currentSelectedRow.status, generalProposalItemIsList, generalProposalItemTierList, generalProposalVM]);

    const handleClose = useCallback(() => {
        generalProposalVM.onTariffItemTierCloseClick();
    }, [generalProposalVM]);


    const handleEdit = useCallback(() => {
        if("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status){
            messageBarVM.showWarining("General Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot be edited.");
            return;
        }
        if(currentTariffItemTier?.tierNo == null){
            messageBarVM.showWarining("Please select a Tier record.");
            return;
        }
        generalProposalVM.onTariffItemTierEidtClick();
    }, [currentSelectedRow.status, currentTariffItemTier?.tierNo, generalProposalVM, messageBarVM]);

    const recordValidate = useCallback(() =>{
        if(!currentSelectedRow.effectiveDate){
            messageBarVM.showWarining("effective Date is mandatory.");
            return false;
        }
        if(!currentSelectedRow.expiryDate){
            messageBarVM.showWarining("expiry Date is mandatory.");
            return false;
            
        }
        if(!currentSelectedRow.currencyCode){
            messageBarVM.showWarining("currency is mandatory.");
            return false;
        }
        return true;
    },[messageBarVM, currentSelectedRow.currencyCode, currentSelectedRow.effectiveDate, currentSelectedRow.expiryDate])


    const handleSave = useCallback(() => {

        // if(generalProposalItemTierList.length !== 1 && !_.isEmpty(generalProposalItemIsList)){
        //     messageBarVM.showWarining("Intensive surcharge does not support multiple charge tier.");
        //     return;
        // }

        if(recordValidate()){
            setIsLoading(true);
            generalProposalVM.onSaveAll(currentSelectedRow,generalProposalItemTierList,generalProposalItemIsList).then(()=>{
                setIsLoading(false);
                if(searchStatus !== ""){
                    generalProposalVM.onGroupButtonClick(searchStatus);
                }else{
                    generalProposalVM.searchGeneralProposal(searchCriteria);
                }
            }).catch((error)=>{
                setIsLoading(false);
                messageBarVM.showError("Failed in save data.")
            })
        }
    },[currentSelectedRow, generalProposalItemIsList, generalProposalItemTierList, generalProposalVM, messageBarVM, recordValidate, searchCriteria, searchStatus]);

    const handleDelete =  useCallback(() => {
        if("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status){
            messageBarVM.showWarining("General Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot be deleted.");
            return;
        }

        if(_.isEmpty(tariffItemTierSelectedRows)){
            messageBarVM.showWarining("Please select a Tier record.");
            return;
        }
        
        if(_.isEmpty(generalProposalItemTierList) || generalProposalItemTierList.length === 1 
            || generalProposalItemTierList.length === tariffItemTierSelectedRows.length){
            messageBarVM.showWarining('At least one tier is required.')
            return;
        }
        generalProposalVM.onDeleteTier();
    },[currentSelectedRow.status, generalProposalItemTierList, generalProposalVM, messageBarVM, tariffItemTierSelectedRows])
    
    const memoGeneralProposalHeaderTable = useMemo(() => {
        return <GeneralProposalHeaderBar/>
    },[])

    const memoBtns = useMemo(()=>{

        const hdrBtns = [];
        if(!(isAddTier&&!isEditTier)){
            hdrBtns.push({                
                id: 'onEditButton',
                icon: 'Icon-pen',
                title: 'Edit'
            })
        }

        if(!(isAddTier || isEditTier)){
            hdrBtns.push({                
                id: 'onDeleteButton',
                icon: 'Icon-trash',
                title: 'Delete'
            })
        }
        
        hdrBtns.push(
            {
                id: 'onCloseButton',
                icon: 'Icon-cross',
                title: 'Close'
            }
        )

        if((isEditingProposal)){
            hdrBtns.push({
                id: 'onTickButton',
                icon: 'Icon-tick',
                title: 'Save'
            })
        }

        return hdrBtns
    },[isAddTier, isEditTier, isEditingProposal]) 

    const handleRowClick = useCallback(()=>{
        
    },[])

    const memoGeneralProposalTariffItemTierTable = useMemo(() => {

        return (
            <HPHTable
                id='general-proposal-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF.slice()}
                data={transferRowData(generalProposalItemTierList ?? [])}
                headerActionButtons={memoBtns}
                onEditButton={handleEdit}
                onDeleteButton={handleDelete}
                onCloseButton={handleClose}
                onTickButton={handleSave}
                showPaginator={false}
                showAddIcon={!(isEditTier&&!isAddTier)}
                onAddClick={handleAdd}
                showUploadIcon={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"single"}
                isRowHighligted={true}
                onRowClick={handleRowClick}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 780px)"
                ref={gridRef}
            />
        );
    }, [generalProposalItemTierList, memoBtns, handleEdit, handleDelete, handleClose, handleSave, isEditTier, isAddTier, handleAdd, handleRowClick, handleSelectionChange])

    const memoTariffItemISTable = useMemo(() => {
        return <TariffItemIsTablePanel/>
    },[])

    const memoTariffItemIsEditPanel = useMemo(() => {
        return <TariffItemIsEditPanel/>
    },[])

    return <><div className="main-comp-wrapper">
        <div style={{width: '100%', height: '100%', maxHeight: '85%'}}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        {memoGeneralProposalHeaderTable}
        <TableWrapper>
        {memoGeneralProposalTariffItemTierTable}
        </TableWrapper>
        {memoTariffItemISTable}
        {<div className={`child-div${(isAddIs||isAddTier||isEditIs||isEditTier)? " child-div-gen-tariff-item-edit-expanded " : ""}`}>
                {memoTariffItemIsEditPanel}
            </div>}
        </div>   
        {/* {
            isEditingProposal && <SidebarActionBar>
            <HPHButton label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />
        </SidebarActionBar>
        }    */}
        
    </div></>;
}

export default memo(TariffItemTierTablePanel);
