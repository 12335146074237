import { approvalProcessStatusDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { GeneralProposalConstant, generalProposalRequiredFieldList } from "presentation/constant/GeneralProposal/GeneralProposalConstant";
import { useGeneralProposalVM } from "presentation/hook/GeneralProposal/useGeneralProposalVM";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { memo, useMemo } from "react";
import { CommonField, FieldType, IFieldValue } from "veronica-ui-component/dist/component/core";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const GeneralProposalHeaderBar:React.FC = () => {
    const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Table;
    const [generalProposalState] = useGeneralProposalTracked();
    const { currentSelectedRow,isEditingProposal } = generalProposalState;
    const { isSaveClicked} = generalProposalState.gnProposalState;
    const generalProposalVM = useGeneralProposalVM();

    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom:"10px",marginLeft: "2px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.tariffType || ''}
                fieldValue={currentSelectedRow?.tariffType}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffType'}
                maxLength={60}
                options={[]}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    generalProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentSelectedRow?.tariffType, GENERAL_PROPOSAL_CONSTANT.TARIFF_TYPE, isSaveClicked, generalProposalVM])

        const memoTarCode = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"185px", marginBottom:"10px",marginLeft: "2px"}}>
                <CommonField
                    errorMessages={{}}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSelectedRow?.tariffCode || ''}
                    fieldValue={currentSelectedRow?.tariffCode}
                    fieldLabel={GENERAL_PROPOSAL_CONSTANT.TARIFF_CODE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffCode'}
                    maxLength={60}
                    options={[]}
                    requiredFieldList={generalProposalRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        generalProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
            , [currentSelectedRow?.tariffCode, GENERAL_PROPOSAL_CONSTANT.TARIFF_CODE, isSaveClicked, generalProposalVM])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "10px",marginLeft: "2px" }}>
            <DatePickerComponent
                    disabled={!isEditingProposal}
                    label={GENERAL_PROPOSAL_CONSTANT.EFFECTIVE_DATE}
                    width="185px"
                    date={currentSelectedRow?.effectiveDate}
                    fieldName="effectiveDate"
                    errorMessage={""}
                    onDateChange={generalProposalVM.onHeaderFieldChange}/>
        </div>
        , [isEditingProposal, GENERAL_PROPOSAL_CONSTANT.EFFECTIVE_DATE, currentSelectedRow?.effectiveDate, generalProposalVM.onHeaderFieldChange])
    
        const memoExpiryDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "10px",marginLeft: "2px" }}>
            <DatePickerComponent
                    disabled={!isEditingProposal}
                    label={GENERAL_PROPOSAL_CONSTANT.EXPIRY_DATE}
                    width="185px"
                    date={currentSelectedRow?.expiryDate}
                    fieldName="expiryDate"
                    errorMessage={""}
                    onDateChange={generalProposalVM.onHeaderFieldChange}/>
        </div>
        , [isEditingProposal, GENERAL_PROPOSAL_CONSTANT.EXPIRY_DATE, currentSelectedRow?.expiryDate, generalProposalVM.onHeaderFieldChange])

    const memoCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom: "10px",marginLeft: "2px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={!isEditingProposal}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.currencyCode || ''}
                fieldValue={currentSelectedRow?.currencyCode}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.CURRENCY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'currencyCode'}
                maxLength={60}
                options={generalProposalState.dynamicOptions.currencyCodeDropdownOptions}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    generalProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isEditingProposal, currentSelectedRow?.currencyCode, GENERAL_PROPOSAL_CONSTANT.CURRENCY, isSaveClicked, generalProposalState.dynamicOptions.currencyCodeDropdownOptions, generalProposalVM])

    const memoStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom:"10px",marginLeft: "2px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.status || ''}
                fieldValue={currentSelectedRow?.status}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.STATUS}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'status'}
                maxLength={60}
                options={approvalProcessStatusDropdownOption}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    generalProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.STATUS, currentSelectedRow?.status, isSaveClicked, generalProposalVM])

    const memoRejectReason = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "10px",marginLeft: "2px" }} >
            <CommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.rejectReason || ''}
                fieldValue={currentSelectedRow?.rejectReason}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.REJECT_REASON}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'rejectReason'}
                maxLength={200}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalVM.onHeaderTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.REJECT_REASON, currentSelectedRow?.rejectReason, generalProposalVM])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' id="std-proposal-edit-remarks"  style={{ width: "405px", marginBottom: "10px",marginLeft: "2px" }} >
            <CommonField
                errorMessages={{}}
                isReadOnly={!isEditingProposal}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.remarks || ''}
                fieldValue={currentSelectedRow?.remarks}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.REMARKS}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'remarks'}
                maxLength={200}
                rows={1}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalVM.onHeaderTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [isEditingProposal, currentSelectedRow?.remarks, GENERAL_PROPOSAL_CONSTANT.REMARKS, generalProposalVM])


    return <Sidebarheader style={{width: '100%'}}>
        <CriteriaItemContainer>
            {memoTarType}
            {memoTarCode}
            {memoEffectiveDate}
            {memoExpiryDate} 
            {memoCurrency}
            {memoStatus}
            {memoRejectReason}
            {memoRemarks}
        </CriteriaItemContainer>
    </Sidebarheader>
}

export default memo(GeneralProposalHeaderBar);